<template>
  <div class="isp-mapa-clientes">
    <h1 class="titulo-card fs-4">Mapa de clientes</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <!-- <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button> -->
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <div class="mapa w-100">
          <div id="map" class="open-street-map"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'

export default {
  name: 'IspMapaClientes',
  components: {
  },
  data() {
    return {
      intervalRefreshItems: undefined,
      osmMap: undefined,
      geocodeList: {},
    }
  },
  methods: {
    initMap() {
      loading(true);
      api.get(`/configs/parameters`).then(res => {
        loading(false);

        this.osmMap = L.map('map').setView([res.data.mapLat, res.data.mapLng], res.data.mapZoom);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            opacity: 0.9,
        }).addTo(this.osmMap);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    refreshItems() {
      api.get(`/isp/subscriptions/geocodes`).then(res => {
        this.processReceivedGeocodeList(res.data);
      });
    },

    /** @param {[]any} geocodeList */
    processReceivedGeocodeList(geocodeList) {
      geocodeList.forEach((x) => {
        // SETUP VARS
        let iconColor = undefined;
        let textColor = 'text-muted';
        let status = '';

        switch (true) {
          case (x.late === false && x.connected === true):
            iconColor = 'blue';
            textColor = 'info';
            status = 'Conectado';
            break;

          case (x.late === true && x.connected === true):
            iconColor = 'yellow';
            textColor = 'warning';
            status = 'Conectado e bloqueado';
            break;

          default:
            iconColor = 'red';
            textColor = 'danger';
            status = 'Offline';
        }

        const text = `
            <p>
              <h6 class="mb-1"><strong>${x.customerName}</strong></h6>
              <span class="text-${textColor}">${status}</span>
            </p>`;

        const exists = Object.keys(this.geocodeList).some(y => y == x.id);
        if (!exists) {
          // creates a new map marker
          const icon = L.icon({
              iconUrl: `/image/map/marker-${iconColor}.png`,
              iconSize: [15, 20],
              iconAnchor: [8, 20],
              popupAnchor: [-3, -50],
              shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
              shadowAnchor: [12, 40],
          });
          let popup = L.popup({offset: [3, 45], content: text});
          let marker = L.marker(
            [x.lat, x.lng],
            {icon: icon},
          );

          marker.addTo(this.osmMap);
          marker.bindPopup(popup);

          const newItem = {
            status: status,
            marker: marker,
            popup: popup,
          };
          this.geocodeList[x.id] = newItem;
        } else {
          // updates existing
          if (this.geocodeList[x.id].status !== status) {
            const icon = L.icon({
                iconUrl: `/image/map/marker-${iconColor}.png`,
                iconSize: [29, 41],
                iconAnchor: [14, 35],
                popupAnchor: [-3, -76],
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
            });
            this.geocodeList[x.id].popup.setContent(text);
            this.geocodeList[x.id].marker.setIcon(icon);
          }
        }
      });
    },
  },
  created() {
    this.$nextTick().then(() => {
      this.initMap();
      this.$nextTick().then(() => {
        setTimeout(() => {
          this.refreshItems();
        }, 500);
      });
    });
    this.intervalRefreshItems = setInterval(this.refreshItems, 30000);
  },
  destroyed() {
    clearInterval(this.intervalRefreshItems);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#map,
.mapa{
  height: calc(100vh - 220px);
  background-color: black;
}

.mapa > .open-street-map {
    width: 100%;
    height: 100%;
}

.div-icon-marker {
  width: 80px;
  height: 80px;
}
</style>
